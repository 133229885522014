var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('el-form',{ref:"headerForm",staticStyle:{"max-width":"630px","padding":"0 60px 0 0px"},attrs:{"model":_vm.headerForm,"label-width":"130px"}},[_vm._l((_vm.headerItems),function(item,index){return _c('div',{key:index},[(item.value === 'payments')?_c('div',_vm._l((_vm.headerForm.payments),function(payment,paymentIndex){return _c('div',{key:paymentIndex},[_vm._l((_vm.paymentItems),function(content,contentIndex){return _c('el-form-item',{key:contentIndex,attrs:{"label":paymentIndex && content.value == 'method' ? `备选支付方式${paymentIndex}` : content.label,"prop":`payments[${paymentIndex}].${content.value}`,"rules":{
              required: content.required || false,
              message: content.message || '必填',
              trigger: ['blur','change']
            }}},[_c('div',[_c('div',{staticClass:"subscription-list"},[(content.type === 'select')?_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":payment[content.value]},on:{"change":function($event){return _vm.selectChange($event, content.value, paymentIndex)}}},_vm._l((_vm.formatterSelectOptionList(content.children)),function(val){return _c('el-option',{key:val.key,attrs:{"label":val.value,"value":val.value}})}),1):(content.type === 'upload')?_c('UploadImageMultiple',{attrs:{"preview-style":"width: 200px; height: 120px;","tips":'最多四张, 建议上传1:1宽高48*48',"file-list":payment[content.value],"limit":4},on:{"update:fileList":function($event){return _vm.$set(payment, content.value, $event)},"update:file-list":function($event){return _vm.$set(payment, content.value, $event)}}}):_c('div',[_c('el-input',{model:{value:(payment[content.value]),callback:function ($$v) {_vm.$set(payment, content.value, $$v)},expression:"payment[content.value]"}})],1)],1)])])}),_c('el-button',{staticStyle:{"margin-left":"130px","margin-bottom":"12px"},on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(payment)}}},[_vm._v(" 删除 ")])],2)}),0):_c('el-form-item',{attrs:{"label":item.label,"prop":item.value,"rules":{
          required: item.required,
          message: item.message,
          trigger: 'blur'
        }}},[_c('el-input',{attrs:{"type":item.type || 'text',"disabled":item.disabled,"min":item.min},scopedSlots:_vm._u([(item.append)?{key:"append",fn:function(){return [_vm._v(" "+_vm._s(item.append)+" ")]},proxy:true}:null],null,true),model:{value:(_vm.headerForm[item.value]),callback:function ($$v) {_vm.$set(_vm.headerForm, item.value, $$v)},expression:"headerForm[item.value]"}})],1)],1)}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onSubmit('headerForm')}}},[_vm._v(" 保存 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAddSubscriptionContent}},[_vm._v(" 新增支付方式 ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }