<template>
  <form-component type="edit" />
</template>

<script>
import FormComponent from './from.vue'
export default {
  components: {
    FormComponent
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped lang="scss">

</style>
