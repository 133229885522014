<template>
  <Layout>
    <!-- <BHeader> -->
    <el-form
      ref="headerForm"
      :model="headerForm"
      label-width="130px"
      style="max-width: 630px;padding:0 60px 0 0px"
    >
      <div
        v-for="(item, index) in headerItems"
        :key="index"
      >
        <div
          v-if="item.value === 'payments'"
        >
          <div
            v-for="(payment, paymentIndex) in headerForm.payments"
            :key="paymentIndex"
          >
            <el-form-item
              v-for="(content, contentIndex) in paymentItems"
              :key="contentIndex"
              :label="paymentIndex && content.value == 'method' ? `备选支付方式${paymentIndex}` : content.label"
              :prop="`payments[${paymentIndex}].${content.value}`"
              :rules="{
                required: content.required || false,
                message: content.message || '必填',
                trigger: ['blur','change']
              }"
            >
              <div>
                <div class="subscription-list">
                  <el-select
                    v-if="content.type === 'select'"
                    style="width:100%"
                    :value="payment[content.value]"
                    @change="selectChange($event, content.value, paymentIndex)"
                  >
                    <el-option
                      v-for="val in formatterSelectOptionList(content.children)"
                      :key="val.key"
                      :label="val.value"
                      :value="val.value"
                    />
                  </el-select>
                  <UploadImageMultiple
                    v-else-if="content.type === 'upload'"
                    preview-style="width: 200px; height: 120px;"
                    :tips="'最多四张, 建议上传1:1宽高48*48'"
                    :file-list.sync="payment[content.value]"
                    :limit="4"
                  />
                  <div v-else>
                    <el-input v-model="payment[content.value]" />
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-button
              style="margin-left:130px; margin-bottom:12px;"
              @click.prevent="removeDomain(payment)"
            >
              删除
            </el-button>
          </div>
        </div>

        <el-form-item
          v-else
          :label="item.label"
          :prop="item.value"
          :rules="{
            required: item.required,
            message: item.message,
            trigger: 'blur'
          }"
        >
          <el-input
            v-model="headerForm[item.value]"
            :type="item.type || 'text'"
            :disabled="item.disabled"
            :min="item.min"
          >
            <template
              v-if="item.append"
              #append
            >
              {{ item.append }}
            </template>
          </el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit('headerForm')"
        >
          保存
        </el-button>
        <el-button
          type="primary"
          @click="handleAddSubscriptionContent"
        >
          新增支付方式
        </el-button>
      </el-form-item>
    </el-form>
    <!-- </BHeader> -->
  </Layout>
</template>
<script>
import {
  getUpdateCountryPayments,
  getCreateCountryPayments,
  getCountryPaymentsById
} from '@/api/paymentModeManagement.js'
import {
  ThirdPartyPaymentTypeEnum
} from '@/utils/selectOptions'
// import { formatterSelectOptionList } from '@/utils/index'
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadeRef: null,
      pictureList: [],
      headerForm: {
        id: '',
        region_code: '',
        payments: [
          {
            img: [],
            text: '',
            type: '',
            method: ''
          }
        ],
        status: 1
      },
      paymentItems: [
        { label: '默认支付方式', value: 'method', type: 'select', children: ThirdPartyPaymentTypeEnum, required: true, message: '请选择支付方式' },
        { label: '文案', value: 'text' },
        { label: '素材', value: 'img', type: 'upload' }
      ],
      headerItems: [
        { label: '国家', value: 'region_code', required: true, message: '请输入国家代码' },
        { label: '支付方式', value: 'payments', validate: 'template', propsName: 'payments' }
      ],
      result: {}
    }
  },

  computed: { picturePath () { return process.env.VUE_APP_CDN } },
  // created () {
  //   this.getEdit()
  // },
  beforeMount () {
    this.getEdit()
  },

  methods: {
    formatterSelectOptionList (children) {
      return children.map(item => ({
        key: item.name,
        value: item.value
      }))
    },
    handleAddSubscriptionContent () {
      this.headerForm.payments.push({
        method: '',
        img: [],
        text: '',
        type: this.headerForm.payments.length - 1
      })
    },
    selectChange (value, key, contentIndex) {
      this.headerForm.payments[contentIndex][key] = value
    },
    removeDomain (item) {
      const index = this.headerForm.payments.indexOf(item)
      if (index !== -1) {
        this.headerForm.payments.splice(index, 1)
      }
    },
    // 编辑回显
    async getEdit () {
      if (this.type === 'add') {
        return
      }
      this.headerItems[0].disabled = true
      try {
        const id = this.$route.params.id
        const res = await getCountryPaymentsById(`admin/country-payments/${id}`)
        // 处理一下 imgCopy问题
        res[0].payment_methods.map((item) => {
          if (item.imgCopy) {
            item.img = item.imgCopy
            delete item.imgCopy
          }
        })
        res[0].payments = res[0].payment_methods
        delete res[0].payment_methods
        this.headerForm = res[0]
      } catch (err) {
        console.log(err)
      }
    },
    // 提交
    async onSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            if (this.type === 'add') {
              await getCreateCountryPayments(this.headerForm)
            } else {
              const id = this.headerForm.id
              await getUpdateCountryPayments(id, this.headerForm)
            }
            this.$router.go(-1)
          } catch (err) {
            this.$message.error(err.response.data.message)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

  }
}
</script>
<style scoped lang="scss">
.payForm {
  // width: 80%;
  .line {
    width: 60%;
    margin-bottom: 20px;
    border-bottom: 2px solid #cccc;
  }
//  .el-input {
//     width: 100%;
//   }
//   .el-select .el-input {
//     width: 100%;
//   }
}
</style>
